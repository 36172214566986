import { Icon } from '@pelando/components';
import { useRouter } from 'next/router';
import React from 'react';
import { Ghost } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { NotFoundContent, NotFoundInfos } from './style';

export const NotFound = () => {
  const { push } = useRouter();
  const { t } = useTranslation('common');

  return (
    <NotFoundContent>
      <NotFoundInfos
        icon={<Icon icon={Ghost} />}
        title={t('not-found-title')}
        buttonTitle={t('not-found-button-text')}
        onClick={() => push('/')}
        content={<span>{t('not-found-content')}</span>}
      />
    </NotFoundContent>
  );
};

export default NotFound;
