import React from 'react';
import { GetStaticProps } from 'next';
import SEO from '../presentation/components/SEO';
import { NotFound } from '../presentation/pages/public/NotFound';

export const Error = () => (
  <>
    <SEO title="Página não encontrada" robots="noindex, nofollow" />
    <NotFound />
  </>
);

export const getStaticProps: GetStaticProps = async () => ({
  props: {},
  revalidate: true,
});

export default Error;
