import styled from 'styled-components';
import { BOTTOM_NAVIGATION_HEIGHT } from '../../../components/BottomNavigation/style';
import EmptyState from '../../../components/EmptyState';
import { EmptyStateButton } from '../../../components/EmptyState/style';
import { HEADER_HEIGHT } from '../../../components/Header/styles';

export const NotFoundContent = styled.div`
  height: calc(100vh - ${HEADER_HEIGHT}px - ${BOTTOM_NAVIGATION_HEIGHT}px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotFoundInfos = styled(EmptyState)`
  ${EmptyStateButton} {
    min-height: 48px;
  }
`;
